import idleCallback, { timings } from "common/utils/ui/idle_callback";
import { waitForWindowVariable } from "common/utils/ui/wait_for";

const executeTopQualityStore = () => {
  // eslint-disable-next-line no-undef
  merchantwidget.start({
    position: "LEFT_BOTTOM",
    sideMargin: 15,
    bottomMargin: 50,
    mobileSideMargin: 15,
    mobileBottomMargin: 5
  });
};

const initTopQualityStoreScript = () => {
  const script = document.createElement("script");
  script.async = true;

  script.src = "https://www.gstatic.com/shopping/merchant/merchantwidget.js";
  document.body.appendChild(script);
};

window.addEventListener("load", () => {
  idleCallback(initTopQualityStoreScript, timings.topQualityStore);
  waitForWindowVariable(
    "merchantwidget",
    () => {
      executeTopQualityStore();
    },
    () => {
      console.error("GoogleTopQualityStore is not loaded"); // eslint-disable-line no-console
    }
  );
});
